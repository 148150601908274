import {NavbarBig, Members, About, Trainings, Partners, Contacts} from "./pages/";

function App() {
  return (
    <>
    <div className="flex flex-col h-full w-full">
      <NavbarBig/>
      <About/>
      <Members/>
      <Trainings/>
      <Partners/>
      <Contacts/> 
    </div>
    </>
  );
}

export default App;
