import React from 'react'

export const About = () => {
  return (
    <div
      id="hero"
      className="flex flex-col w-full justify-center items-center text-center space-y-24 "
      // px-5 md:px-20
    >
      <div className="flex items-center justify-evenly w-full h-screen bg-cover" style={{backgroundImage: "url('/images/bg_photo.JPG')"}}> 
        
          <div className="max-w-xl space-y-6 bg-white rounded-md py-20 px-20" >
            <div className="text-2xl font-bold">
              Наша миссия
            </div>
            <div className="">
              Способствовать повышению эффективности обучения и развитию профессиональных компетенций педагогов с использованием инновационных форм преподавания
            </div>
        </div>
        
      </div>

      <div className="max-w-xl space-y-6 bg-white/10 rounded-md">
          <div className="text-2xl font-bold">
            Наша работа в цифрах
          </div>
          <p className="">
            16 лет опыта работы в сфере образования
          </p>
          <p className="">
            15 000+ слушателей курсов 
          </p>
        </div>

        <div className='grid gap-x-8 gap-y-12 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 sm:gap-y-16 xl:col-span-2 md:px-20 px-5 py-10'>
          <div className='flex flex-col max-w-sm space-y-5 text-sm'>
            <div className='font-bold'>ОБУЧЕНИЕ УЧИТЕЛЕЙ ОБЩЕОБРАЗОВАТЕЛЬНЫХ ШКОЛ ПО 10 ТЕМАМ В ПАРТНЕРСТВЕ С МОФ «БІЛІМ- ИННОВАЦИЯ»<br></br> </div>
            <div className='text-xs '> 
            •	При поддержке фонда Нурсултана Назарбаева 243 учителя трех школ Костанайской области <br></br>•	3851 учитель 50 школ при Управлении образования Алматинской области;
            </div>
          </div>

          <div className='flex flex-col max-w-sm space-y-5 text-sm'>
            <div className='font-bold'>МАСШТАБНЫЙ ПРОЕКТ ПО ОБУЧЕНИЮ УЧИТЕЛЕЙ ФИЗИКИ, БИОЛОГИИ, ХИМИИ И ИНФОРМАТИКИ<br></br> </div>
            <div className='text-xs '> 
            Учителя гг. Уральск, Петропавловск, Кызылорда, Актау, Рудный, Астана, Алматы, Хромтау, Атырау, Экибастуз, Тараз, Жанаозен, Караганда, а также Алматинской, Восточно-Казахстанской и Южно- Казахстанской областей.
            </div>
          </div>

          <div className='flex flex-col max-w-sm space-y-5 text-sm'>
            <div className='font-bold'>РЯД КРУПНЫХ ПРОЕКТОВ ПО ПОДГОТОВКЕ УЧИТЕЛЕЙ АНГЛИЙСКОГО ЯЗЫКА К МЕЖДУНАРОДНЫМ ЭКЗАМЕНАМ:<br></br> </div>
            <div className='text-xs '> 
            •	TKT (Teaching Knowledge Test ) <br></br>
            •	TKT CLIL (Content Language Integrated Learning) <br></br>
            •	CELTA (Certificate of English Language Teaching for Adults)
            </div>
          </div>
        </div>
        
    </div>
  )
}
