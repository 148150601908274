import React from 'react'
import logo_ktl from "../resources/images/ktl.jpeg"
import logo_jelken from "../resources/images/logo_jelken.jpg"
import logo_akimat_almaty from "../resources/images/logo_almaty_akimat.jpg"
import logo_upr from "../resources/images/logo_upr.jpg"
import logo_ustaz from "../resources/images/logo_ustaz.png"
import logo_enu from "../resources/images/logo_enu.png"
import logo_khabar from "../resources/images/logo_khabar.jpg"
import logo_buketov from "../resources/images/logo_buketov.jpg"

export const Partners = () => {
  return (
    <div className="bg-white py-20">
      <div className="mx-auto max-w-8xl px-6 lg:px-8">
        <h2 className="text-center text-3xl font-semibold leading-8 text-gray-900">
          Наши партнеры
        </h2>
        <div className="grid gap-x-8 grid-cols-2 gap-y-12 sm:grid-cols-4 sm:gap-y-16 xl:col-span-2">
          <div className='flex flex-col max-w-[158px] justify-center mx-auto text-center'>
            <img src={logo_ktl} alt="" className='w-full'/>
            <p>МОФ “Білім-Инновация” </p>
          </div>
          <div className='flex flex-col max-w-[158px] justify-center mx-auto text-center'>
            <img src={logo_jelken} alt="" className='w-full'/>
            <p>ОФ “Jelken Foundation”</p>
          </div>
          <div className='flex flex-col max-w-[158px] justify-center mx-auto text-center'>
            <img src={logo_akimat_almaty} alt="" className='w-full p-5 rounded-full'/>
            <p>Акимат Алматинской области</p>
          </div>
          <div className='flex flex-col max-w-[158px] justify-center mx-auto text-center'>
            <img src={logo_upr} alt="" className='w-full p-5 rounded-full'/>
            <p>Управление образования акимата Алматинской области</p>
          </div>
          <div className='flex flex-col max-w-[158px] justify-center mx-auto text-center'>
            <img src={logo_khabar} alt="" className='w-full p-5 rounded-full'/>
            <p>Телеканал Хабар</p>
          </div>
          {/* <div className='flex flex-col max-w-[158px] justify-center mx-auto text-center'>
            <img src={logo_akimat_almaty} alt="" className='w-full p-5 rounded-full'/>
            <p>ГУ “Министерство Внутренних дел РК”</p>
          </div> */}
          <div className='flex flex-col max-w-[158px] justify-center mx-auto text-center'>
            <img src={logo_buketov} alt="" className='w-full p-5 rounded-full'/>
            <p>Карагандинский государственный университет имени Е. А. Букетова</p>
          </div>
          <div className='flex flex-col max-w-[158px] justify-center mx-auto text-center'>
            <img src={logo_enu} alt="" className='w-full p-5 rounded-full'/>
            <p>Евразийский национальный университет имени Л. Н. Гумилёва</p>
          </div>
          <div className='flex flex-col max-w-[158px] justify-center mx-auto text-center'>
            <img src={logo_ustaz} alt="" className='w-full p-5 rounded-full'/>
            <p>Ustaz Pro</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners