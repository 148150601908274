import React, { useState } from "react";

import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import { FiDownload } from "react-icons/fi";

export const Trainings = () => {
  const slides = [
    {
      url: "/images/train1.jpeg",
    },
    {
      url: "/images/train2.jpeg",
    },
    {
      url: "/images/train3.jpeg",
    },
    {
      url: "/images/train4.jpeg",
    },
    {
      url: "/images/train5.jpeg",
    },
    {
      url: "/images/train6.jpeg",
    },
    
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div className='flex flex-col md:px-20 px-5 py-10 max-w-7xl mx-auto'>
      <div className="flex flex-col w-full">
        <p className="text-2xl mx-auto justify-center font-bold">Проекты программ</p>
        <p className="text-xl font-bold py-10">Проекты образовательных программ для публичного обсуждения</p>
        <div className="flex flex-wrap justify-around space-y-3">

          <div className="border border-gray-100 rounded-md h-48px max-w-[360px]">
              <div className="flex flex-row justify-center items-center">
                <a href='/documents/ПланВоспитатели.docx'>
                  <div className="flex flex-row rounded-md px-5 py-3 items-center gap-5 text-black bg-white">
                    <FiDownload size={24}/>
                    <div className="flex flex-col space-y-3 ">
                      <p className="">Дата создания: 02.05.2024 </p>
                      <p>
                      Учебно-тематический план образовательной программы для педагогов (воспитателей) ДО
                      </p>
                    </div>
                  </div>
                </a>
              </div>
          </div>

          <div className="border border-gray-100 rounded-md h-48px max-w-[360px]">
            <div className="flex flex-row justify-center items-center">
              <a href='/documents/ПланМетодисты.docx'>
                <div className="flex flex-row rounded-md px-5 py-3 items-center gap-5 text-black bg-white">
                  <FiDownload size={24}/>
                  <div className="flex flex-col space-y-3 ">
                    <p className="">Дата создания: 02.05.2024 </p>
                    <p>
                    Учебно-тематический план образовательной программы для методистов ДО
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className="border border-gray-100 rounded-md h-48px max-w-[360px]">
            <div className="flex flex-row justify-center items-center">
              <a href='/documents/ПланРуководители.docx'>
                <div className="flex flex-row rounded-md px-5 py-3 items-center gap-5 text-black bg-white">
                  <FiDownload size={24}/>
                  <div className="flex flex-col space-y-3 ">
                    <p className="">Дата создания: 02.05.2024 </p>
                    <p>
                    Учебно-тематический план образовательной программы для руководителей ДО
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className="border border-gray-100 rounded-md h-48px max-w-[360px]">
            <div className="flex flex-row justify-center items-center">
              <a href='/documents/ПланПсихологи.docx'>
                <div className="flex flex-row rounded-md px-5 py-3 items-center gap-5 text-black bg-white">
                  <FiDownload size={24}/>
                  <div className="flex flex-col space-y-3 ">
                    <p className="">Дата создания: 02.05.2024 </p>
                    <p>
                    Учебно-тематический план образовательной программы для психологов ДО
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className="border border-gray-100 rounded-md h-48px max-w-[360px]">
            <div className="flex flex-row justify-center items-center">
              <a href='/documents/ПланСпецПедагоги.docx'>
                <div className="flex flex-row rounded-md px-5 py-3 items-center gap-5 text-black bg-white">
                  <FiDownload size={24}/>
                  <div className="flex flex-col space-y-3 ">
                    <p className="">Дата создания: 02.05.2024 </p>
                    <p>
                    Учебно-тематический план образовательной программы для специальных педагогов ДО
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        
      </div>

      <div className='flex md:flex-row items-center flex-col pt-10'>
        <div className="md:w-1/2 max-w-xl">
          <p className="mb-6 font-bold text-xl">
            Наши тренеры
          </p>
          <div>
            •	Лучшие отечественные эксперты и учителя <br></br>
            •	Разработчики нормативно-правовых актов в сфере образования РК; Ассоциированные профессоры ВУЗов <br></br>
            •	Учителя лицеев “Білім-Инновация” <br></br>
            •	Ведущие международные эксперты из Англии, Австралии, Швеции, Финляндии, Сингапура, Польши, Малайзии, Венгрии и Италии и др <br></br>
            •	Специалисты "Института раннего развития детей" Министерства просвещения РК <br></br>
            •	Основатели лучших зарубежных детских учреждений и другие <br></br>
          </div>
        </div>
          
        <div className="md:w-1/2 w-full h-[480px] m-auto py-16 px-4 relative group">
        <div
          style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
          className="w-full h-full rounded-2xl bg-center bg-cover duration-500"
        ></div>
        <div
          onClick={prevSlide}
          className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer"
        >
          <BsChevronCompactLeft size={30} />
        </div>
        <div
          onClick={nextSlide}
          className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer"
        >
          <BsChevronCompactRight size={30} />
        </div>
        <div className="flex top-4 justify-center py-2">
          {slides.map((slide, slideIndex) => (
            <div
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
              className="text-2xl cursor-pointer"
            >
              <RxDotFilled />
            </div>
          ))}
        </div>
      </div>
      </div>
    </div>
  )
}

export default Trainings