import React from 'react'
import photo1 from '../resources/images/Керимбаева.jpg'
import photo2 from '../resources/images/Скакова.png'
import photo3 from "../resources/images/Оспанова.jpg"
import photo4 from "../resources/images/Мусатай.jpg"
import photo5 from "../resources/images/Каркинбаев.jpg"

const people = [
  {
    name: 'Керимбаева Эльмира Саметовна',
    role: 'Исполнительный директор по реализации услуг',
    imageUrl:
    photo1,
  },
  {
    name: 'Скакова Жумагуль Ермековна',
    role: 'Руководитель академического отдела',
    imageUrl:
      photo2,
  },
  {
    name: 'Оспанова Анар Капаровна',
    role: 'Руководитель проектного офиса по услугам',
    imageUrl:
      photo3,
  },
  {
    name: 'Мұсатай Назгүл Жанболатқызы',
    role: 'Менеджер проектного офиса по услугам',
    imageUrl:
      photo4,
  },
  {
    name: 'Каркинбаев Асанали Ринатович ',
    role: 'Менеджер переводческого отдела',
    imageUrl:
      photo5,
  },
]

export const Members = () => {
  return (
    <div className="bg-white py-20 md:px-20 px-5">
      <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
        <div className="max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Наша команда</h2>
          
        </div>
        <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
          {people.map((person) => (
            <li key={person.name}>
              <div className="flex items-center gap-x-6">
                <img className="h-48 w-32 rounded-md" src={person.imageUrl} alt="" />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                  <p className="text-sm font-semibold leading-6 text-[#2B6B6F]">{person.role}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Members